import React from 'react';
import {Spring, Transition, animated} from 'react-spring/renderprops';
import SliderModal from './SliderModal';
import StepsCircle from '../SvgComponents/StepsCircle';
import PaymentRadio from './PaymentRadio';
import {connect} from 'react-redux';
import {css} from 'emotion';
import styled from 'styled-components';
import PulseLoader from 'react-loaders-spinners';
import Row from '../Row';

import axios from 'axios';
import { urls as urls } from '../../api/urls';
import { loginUser, updateUser, fetchProfile, flushUserState } from '../../actions/userActions';
import Helmet from 'react-helmet';
import Moment from 'moment';
import { updatePaymentId, bidSubmitted, updateBidId, submitBid, flushOrderState } from '../../actions/orderActions';
import { navigate } from "gatsby";
import { flushCarsState } from '../../actions/carsActions';
import { flushCouponState } from '../../actions/couponActions';
import { flushRideState } from '../../actions/rideActions';
import { flushTripState } from '../../actions/tripActions';
import { updateSelectedVendor, updateRemarks } from '../../actions/orderActions';

/* global tw */
const RelativeWrapper = styled.div`
    ${tw`w-full px-4 py-12 lg:px-12 h-full overflow-hidden relative`}
`

const GreetingLine = styled.p`
    ${tw`w-full my-2 font-medium text-grey-dark text-xl`};
`

const InputLabel= styled.label`
    ${tw`font-black lg:text-4xl tracking-tight`};
`

const InputField = styled.input`
    ${tw`font-bold w-full py-2 h-24 bg-grey-light px-2 appearance-none outline-none text-xl shadow-none lg:text-4xl border-none`};
    box-shadow: none;

`
const ProceedButton = styled.div`
    ${tw`flex items-center justify-center absolute pin-b my-6 w-3/4 mx-auto pin-x py-6 px-4 rounded-full text-white bg-purple cursor-pointer font-bold`}
`
class SubmitForm extends React.Component{

    constructor(props){
        super(props);

        this.state = {
            step : 1,
            greeting: `You're Awesome!`,
        }

        this.handleChange = this.handleChange.bind(this);

    }

    componentWillMount(){
        if(this.props.isUserLoggedIn){
            this.setState({
                step: 2,
                greeting: `Hey, ${this.props.user.name.split(' ')}!`
            });
        }
    }

    handleChange(event) {
        
        this.setState({ [event.target.name]: event.target.value });
    }

    handleNext(){
        
        this.setState({ error: '' });
        if (this.state.step === 1) {
            if (this.state.name.length < 2) {
                // this.setState({ error: "Please Enter a Valid Name" });
                return;
            }
            // this.setState({ step: this.state.step + 1 });
        }
        else if (this.state.step === 2) {
            if (this.state.email.length === 0 || !this.state.email.includes('@')) {
                // this.setState({ error: "Please Enter a Valid Email" });
                return;
            }
            // this.setState({ step: this.state.step + 1 });
        }

        else if (this.state.step === 3) {
            if (this.state.phone.length !== 10) {
                this.setState({ error: "Please Enter a Valid Phone Number" });
                return;
            }
            // this.sendOtp();
            // this.setState({ step: this.state.step + 1 });
        }
        else if (this.state.step === 4) {
            // this.authenticateOtp();
        }
        else if (this.state.step === 5) {
            if (this.state.remarks !== '') {
                this.props.dispatch(updateRemarks(this.state.remarks));
            }

            //submitting order without advance payment for only zyppys if done by customer care
            if (this.state.remarks === 'customer care' && (this.state.selectedVendor.account_id === 1 || this.state.selectedVendor.account_id === null)) {
                // this.submitBid();
            }
            else {
                this.setState({ step: this.state.step + 1 });
            }
        }
        else if (this.state.step === 6) {
            // this.setState({step:this.state.step+1});
            // this.openRazorpay();
        }
    }

    render(){
        return (
            <SliderModal active={this.props.active}>
                <RelativeWrapper>
                    <Row>
                        <Transition
                            items={this.state.greeting}
                            from={{ opacity: 0 }}
                            enter={{ opacity: 1 }}
                            leave={{ opacity: 0 }}>
                            {item => props => (
                                <GreetingLine style={props}>
                                    {item}
                                </GreetingLine>
                            )}
                        </Transition>
                        <Transition
                            items={this.props.isUserLoggedIn && this.state.step === 1 }
                            from={{ opacity: 0 }}
                            enter={{ opacity: 1 }}
                            leave={{ opacity: 0 }}>
                            {show => show && (
                                props => 
                                <h3 style={props}>{this.props.user.name}</h3>
                            )}
                        </Transition>
                        <Transition
                            items={this.state.step === 1}
                            from={{ opacity: 0 }}
                            enter={{ opacity: 1 }}
                            leave={{ opacity: 0 }}>
                            {show => show && (
                                props => 
                                <InputLabel style={props}>What do we call you?</InputLabel>
                            )}
                        </Transition>
                    </Row>

                    <Row>
                        <InputField value={this.state.name}
                                    name={'name'} 
                                    placeholder={'Your Name?'}
                                    onChange={this.handleChange}/>
                    </Row>


                    <ProceedButton onClick={this.handleNext}>
                        Proceed
                    </ProceedButton>
                </RelativeWrapper>

            </SliderModal>
        );
    }
} 


const mapStateToProps = (state) => ({
    ride: state.ride,
    isUserLoggedIn: state.user.isUserLoggedIn,
    user: state.user,
})


export default connect(mapStateToProps)(SubmitForm);