import React from 'react';
import {css} from 'emotion';
import {Spring, Transition, animated} from 'react-spring/renderprops';

/*global tw*/

const styles = {
    wrapper: css`
        ${tw`fixed pin-b mx-auto z-300 overflow-hidden`}
        background: white;
        border-radius: 8px 8px 0 0;
        @media(min-width: 992px){
            border-radius: 16px 16px 0 0;
            // maxwidth: 80.9%;
            max-width: 80rem;
            
        }
        width: 100%;
        max-height: 65.8vh;
        height: 65.8vh;
        left: 0;
        right: 0;
        opacity: 1;
        
    `,
}
const SliderModal = (props) => (
    <Spring native 
            onStart={props.onStart}
            from={{ y: 25 }}
            to={{y: props.active ? 0: 100}}>
        {({y}) =>

            <animated.div 
                className={styles.wrapper} 
                style={{
                    transform: y.interpolate(y => `translateY(${y}vh)`),
                    marginBottom: props.placesModal ? 0 : 0
                }} 
                onClick={e => e.stopPropagation()}>
                <div className={css`${tw`h-full w-full relative z-0 overflow-hidden`};`}>
                    {props.children}
                    {props.hasDone &&
                        <div
                            onClick={props.onDone} 
                            className={css`${tw`flex w-full pin-b pin-x mx-auto absolute z-10 py-5 flex text-xl items-center justify-center text-white font-medium bg-purple text-grey-lightest cursor-pointer overflow-hidden`}; background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%)`}>

                            <span>Confirm</span>
                            {/*<div className={css`${tw`relative inline-flex items-center relative h-full ml-2`}; width: 14rem; `}>
                                <Transition
                                    native
                                    items={props.buttonLang}
                                    from={{ position: 'absolute', opacity: 0, transform: `translate3d(0, 20px, 0)` }}
                                    enter={[{ opacity: 1, transform: `translate3d(0, 0, 0)` }]}
                                    leave={{ opacity: 0, transform: `translate3d(0, -20px, 0)` }}
                                    >
                                {show =>
                                    show && (values => <animated.p className={css`${tw`pin-y font-bold`}`} style={values}>
                                        {props.buttonLang && props.buttonLang}
                                    </animated.p>)
                                }
                                </Transition>
                            </div>*/}
                        </div>
                    }
                    {props.filter &&
                        <div
                            onClick={props.filterClick} 
                            className={css`${tw`flex w-1/3 lg:w-1/6 pin-b pin-x mx-auto mb-4 rounded-full absolute z-10 py-6 flex text-xl items-center justify-center lg:justify-start text-white font-medium text-grey-lightest cursor-pointer overflow-hidden`}; height: 2rem; background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%)`}>
                            <img src={require('../../assets/img/filter.png')} height='40' width='40'className={css`${tw`lg:ml-px lg:mr-10`}`}/>
                            <span>Filter</span>
                           
                        </div>
                    }
                </div>
            </animated.div>
        }
    </Spring>
);

export default SliderModal;