import React from 'react';
import {css} from 'emotion';
import {Spring, config, interpolate, animated, easings} from 'react-spring/renderprops'; 


/*global tw */
class StepsCircle extends React.Component{
    constructor(props){
        super(props);
        this.state = { offset: 0 }
        this.path = React.createRef()
    }
    componentDidMount() {
        this.setState({ offset: this.path.current.getTotalLength() });
        console.log('path length: ' + this.path.current.getTotalLength() );
    }
    render(){
        const offset = this.state.offset;
        // console.log()
        return(
            <svg xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 85 85" 
                height={this.props.height} 
                width={this.props.width} 
                className={css`${tw`${this.props.twClasses}`}`}>

                <g fill="none" fillRule="evenodd" transform="translate(3 3)">
                    <circle cx="40.905" cy="40.905" r="40.905" fill="#F0F2F5" />
                    <Spring
                        native
                        reset
                        from={{ dash: 0 }}
                        to={{ dash: offset / this.props.currentStep }}>
                        {props => (
                            <animated.path
                                ref={this.path}
                                strokeDasharray={offset}
                                strokeDashoffset={props.dash}
                                stroke="#7D0EDE"
                                strokeWidth="4.86"
                                d="M40.905 0C18.3137923 0 0 18.3137923 0 40.905" 
                                // d="M40.905 84.24C16.972 84.24-2.43 64.838-2.43 40.905S16.972-2.43 40.905-2.43 84.24 16.972 84.24 40.905 64.838 84.24 40.905 84.24zm0-4.86c21.25 0 38.475-17.226 38.475-38.475 0-21.25-17.226-38.475-38.475-38.475-21.25 0-38.475 17.226-38.475 38.475 0 21.25 17.226 38.475 38.475 38.475z" 
                                // d="M43.905 87.24C19.972 87.24.57 67.838.57 43.905S19.972.57 43.905.57 87.24 19.972 87.24 43.905 67.838 87.24 43.905 87.24zm0-4.86c21.25 0 38.475-17.226 38.475-38.475 0-21.25-17.226-38.475-38.475-38.475-21.25 0-38.475 17.226-38.475 38.475 0 21.25 17.226 38.475 38.475 38.475z"
                                />
                        )}
                    </Spring>
                    <text fill="#222" font-family="Chromatica-Light, Chromatica" font-size="29.6865" font-weight="300" letter-spacing="-1.0125" opacity=".55000001">
                        <tspan x="25.11" y="54.16">{this.props.currentStep}/{this.props.totalSteps}</tspan>
                    </text>
                </g>
            </svg>

            // <svg xmlns="http://www.w3.org/2000/svg" baseProfile="tiny" viewBox="0 0 100 100">
            //     <circle cx="50" cy="50" r="43.2" fill="#FFF" />
            //     <Spring
            //             native
            //             // reset
            //             from={{ dash: 0 }}
            //             to={{ dash: offset / this.props.currentStep }}>
            //             {props => (
            //                 // <animated.path
            //                 //     ref={this.path}
            //                 //     strokeDasharray={offset}
            //                 //     strokeDashoffset={props.dash}
            //                 //     stroke="#7D0EDE"
            //                 //     strokeWidth="4.86"
            //                 //     d="M40.905 0C18.3137923 0 0 18.3137923 0 40.905" 
            //                 //     // d="M40.905 84.24C16.972 84.24-2.43 64.838-2.43 40.905S16.972-2.43 40.905-2.43 84.24 16.972 84.24 40.905 64.838 84.24 40.905 84.24zm0-4.86c21.25 0 38.475-17.226 38.475-38.475 0-21.25-17.226-38.475-38.475-38.475-21.25 0-38.475 17.226-38.475 38.475 0 21.25 17.226 38.475 38.475 38.475z" 
            //                 //     // d="M43.905 87.24C19.972 87.24.57 67.838.57 43.905S19.972.57 43.905.57 87.24 19.972 87.24 43.905 67.838 87.24 43.905 87.24zm0-4.86c21.25 0 38.475-17.226 38.475-38.475 0-21.25-17.226-38.475-38.475-38.475-21.25 0-38.475 17.226-38.475 38.475 0 21.25 17.226 38.475 38.475 38.475z"
            //                 //     />
            //                 <animated.circle 
            //                     cx="50" 
            //                     cy="50" 
            //                     r="43.2" 
            //                     fill="none" 
            //                     stroke="#7D0EDE"
            //                     ref={this.path}
            //                     strokeDasharray={offset}
            //                     strokeDashoffset={props.dash} 
            //                     strokeMiterLimit="10" 
            //                     strokeWidth="5"/>
            //             )}
            //         </Spring>
            //     <text fill="#4D4D4D" font-family="'Chromatica-Medium'" font-size="30" transform="translate(28.775 63.363)">
            //                 {this.props.currentStep}
            //     </text>
            //             <text fill="#4D4D4D" font-family="'Chromatica-Medium'" font-size="30" transform="translate(41.045 63.363)">
            //                 /
            //     </text>
            //             <text fill="#4D4D4D" font-family="'Chromatica-Medium'" font-size="30" transform="translate(53.464 63.363)">
            //                 {this.props.totalSteps}
            //     </text>
            // </svg>
        );
    }

}

export default StepsCircle;



let newSvg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" baseProfile="tiny" viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="43.2" fill="#FFF" />
        <circle cx="50" cy="50" r="43.2" fill="none" stroke="#7D0EDE" stroke-miterlimit="10" stroke-width="5" />
        <text fill="#4D4D4D" font-family="'Chromatica-Medium'" font-size="30" transform="translate(28.775 63.363)">
            1
        </text>
        <text fill="#4D4D4D" font-family="'Chromatica-Medium'" font-size="30" transform="translate(41.045 63.363)">
            /
        </text>
        <text fill="#4D4D4D" font-family="'Chromatica-Medium'" font-size="30" transform="translate(53.464 63.363)">
            4
        </text>
    </svg>
);