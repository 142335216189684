import React from 'react';
import SubmitForm from '../components/appComponents/SubmitForm';
import styled from 'styled-components';
import Menu from '../components/appComponents/Menu';


/* global tw */
const PageWrapper = styled.div`
    ${tw`max-h-screen h-screen w-screen bg-black relative overflow-hidden`};
`
const componentBuilder = (props)=>(
    
    <PageWrapper>
        {/* <SubmitForm active/> */}
        <Menu/>
    </PageWrapper>
    

);

export default componentBuilder;